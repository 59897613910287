// @flow

import * as React from 'react'

import * as styles from './SerialDilution.module.css'

import {Material, BulletPoint} from '../../ui-components'

import {MATERIAL_DATA, MATERIAL_BULLET_POINT_DATA} from './serial-dilution-data'

export default function OpentronsMaterials() {
  return (
    <div>
      <section className={styles.opentrons_materials}>
        <h4 className={styles.h4}>Opentrons Materials</h4>
        <div>
          {MATERIAL_DATA.map((material, i) => {
            return <Material key={i} {...material} />
          })}
        </div>
        <div className={styles.material_container}>
          <p className={styles.p}>Additional Materials</p>
          <div className={styles.additional_materials}>
            <BulletPoint names={MATERIAL_BULLET_POINT_DATA} />
          </div>
        </div>
      </section>
    </div>
  )
}
