// @flow

import * as React from 'react'

import * as styles from './SerialDilution.module.css'

import {BulletPoint, AppLink} from '../../ui-components'

import {WORKFLOW_BULLET_POINT_DATA} from './serial-dilution-data'

const OT_ROBOT_URL = 'https://shop.opentrons.com/products/ot-2'
const PROTOCOL_LIBRARY_URL =
  'https://protocols.opentrons.com/categories/Basic%20Pipetting/Dilution'
export default function Overview() {
  return (
    <div className={styles.overview_container}>
      <section>
        <h4 className={styles.h4}>Overview</h4>
        <p className={styles.p}>
          A serial dilution is the sequential dilution of a substance in
          solution. It is a basic protocol for nearly every life science lab,
          and is used routinely to create standard curves, create working stocks
          of samples, and more. While serial dilutions are critical to the
          success of many workflows, they are repetitive and time-consuming,
          making them perfect processes for biology lab automation. The{' '}
          <AppLink url={OT_ROBOT_URL}>
            Opentrons OT-2 liquid handling robot
          </AppLink>{' '}
          can easily perform automated serial dilutions. Just input your desired
          dilution factor, number of dilution, and volume on our{' '}
          <AppLink url={PROTOCOL_LIBRARY_URL}>Prov tocol Library</AppLink>, then
          download your protocol, and run it on your OT-2.
        </p>
        <div className={styles.workflow}>
          <p className={styles.p}>
            This workflow can be used for the following applications:
          </p>
          <div>
            <BulletPoint names={WORKFLOW_BULLET_POINT_DATA} />
          </div>
        </div>
      </section>
    </div>
  )
}
