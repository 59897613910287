import {PRICES} from '../../prices'

export const WORKFLOW_DATA = [
  {
    imgSrc: require('./assets/step-1_200x150.png').default,
    stepNumber: '1',
    name: 'Place reagents onto robot',
    content:
      'Load reagents and labware onto the deck of the OT-2. Ensure tubes are labelled properly.',
  },
  {
    imgSrc: require('./assets/step-2_200x150.png').default,
    stepNumber: '2',
    name: 'Choose your protocol',
    content:
      'Just input your desired dilution factor, number of dilutions, and volume on our Protocol Library, then download your protocol, and run it on your OT-2.',
  },
  {
    imgSrc: require('./assets/step-3_200x150.png').default,
    stepNumber: '3',
    name: 'Calibrate & activate protocol',
    content: 'Confirm pipetting locations and hit “run”',
  },
  {
    imgSrc: require('./assets/step-4_200x150.png').default,
    stepNumber: '4',
    name: 'Run your assay',
    content:
      'With a perfect serial dilution complete, you’re now free to run your assay with precise standard curves.',
  },
]

export const MATERIAL_DATA = [
  {
    url: 'https://shop.opentrons.com/ot-2-robot/',
    imgSrc: require('../../images/application/materials/opentrons_ot2.jpg')
      .default,
    name: 'OT-2 Liquid Handling Robot',
    price: 'Starting at under $10,000',
  },
  {
    url: 'https://shop.opentrons.com/8-channel-electronic-pipette/',
    imgSrc:
      require('../../images/application/materials/multi_channel_pipette.jpg')
        .default,
    name: '8-Channel Pipette',
    price: PRICES.MultiChannelPipette,
  },
  {
    url: 'https://shop.opentrons.com/universal-filter-tips//products/opentrons-300ul-tips/',
    imgSrc: require('../../images/application/materials/filter_tips.jpg')
      .default,
    name: '300 µL Tip Rack (incl. 9,600 tips)',
    price: '$500.00',
  },
]

export const NOTE_DATA = [
  {
    name: 'Labware',
    note: 'The OT-2 supports various labware for serial dilution. While the labware you use may vary based on the reagents, aluminum block, or temperature module requirements for your lab, we recommend the 12-channel reservoirs designed for automation to every lab doing serial dilutions. This 12-channel reservoir, plus a 96-well PCR plate, clear flat bottom microplate, and molecular biology grade water, are what we used to optimize and verify our serial dilution protocol. (view technical note) If you prefer to use different labware, you can most likely find a close match in our Labware Library, or reach out to our support team for help setting it up.',
  },
  {
    name: 'Pipettes',
    note: 'Ensure that your OT-2 is fitted with suitable pipettes for the volumes you will need to work with. All of our pipettes can support serial dilution, but pipette channel and size can vary based on your protocol. For example, if you’re diluting a 200 ml sample, a p300 pipette would suit that protocol well. You can also use a multichannel pipette to perform the dilution faster without needing to adapt the protocol. You can even choose to reuse tips by having the pipette hover over the well to dispense liquid rather than trash them, saving resources and lab costs. Please review our Opentrons Pipette White Paper for more information on our pipettes and pipetting volumes as low as 1 µL.',
  },
]

export const RESOURCE_NOTE_DATA = [
  {
    url: 'https://s3.amazonaws.com/opentrons-protocol-library-website/Technical+Notes/Serial+Dilution+OT2+Technical+Note.pdf',
    noteType: 'Technical Note',
    name: 'Serial Dilution Technical Note',
    iconName: 'pdf-download',
  },
  {
    url: 'https://s3.amazonaws.com/opentrons-landing-img/pipettes/OT-2-Pipette-White-Paper.pdf',
    noteType: 'Technical Note',
    name: 'Opentrons Pipette White Paper',
    iconName: 'pdf-download',
  },
  {
    url: 'https://protocols.opentrons.com/protocol/customizable_serial_dilution_ot2/',
    noteType: 'Protocol',
    name: 'Serial Dilution Protocol',
    iconName: 'protocol',
  },
]

export const RELATED_CONTENT_DATA = [
  {
    url: 'https://blog.opentrons.com/the-power-of-open-source-in-biology-damp-labs-10k-biofoundry/',
    readInfo: 'BLOG - 6 MIN READ',
    title: `The Power of Open Source in Biology—DAMP Lab’s $10k Biofoundry`,
    content:
      'The DAMP Lab is a fledgling biofoundry within the new Biological Design Center at Boston University.',
  },
  {
    url: 'https://blog.opentrons.com/ngs-automation/',
    readInfo: 'BLOG - 4 MIN READ',
    title: `5 Reasons to Automate your NGS Workflow`,
    content:
      'With greater speed, lower costs, and higher throughput than first generation methods, next generation sequencing (NGS) has catapulted biological research into a new era.',
  },
]

export const MENU_DATA = {
  links: [
    'overview',
    'benefits',
    'workflow',
    'opentrons materials',
    'notes',
    'resources',
  ],
  url: '/automated-serial-dilution',
  workflow: 'Interested in automating your serial dilutions?',
}

export const WORKFLOW_BULLET_POINT_DATA = [
  'Biochemistry',
  'Pharmacology',
  'Microbiology',
  'Molecular Biology',
  'Genetics',
  'Proteomics',
  'Beer manufacturing',
  'Forensics',
  'Clinical applications',
  'Cosmetic science',
  'Ecology',
  'Plant cell biology',
  'Food science',
  'Neurology',
]

export const MATERIAL_BULLET_POINT_DATA = [
  'USA Scientific 12-channel reservoir for automation (1061-8150)',
  'Corning 96-well EIA/RIA Easy Wash™ Clear Flat Bottom Polystyrene (3368)',
  'Corning 500 mL Molecular Biology Grade Water (46-000-CV)',
  'McCormick Assorted Food Color and Egg Dye - Blue',
  'Tecan Infinite F200 Pro',
]

export const BENEFIT_LIST_DATA = [
  {
    boldText: 'Increased speed',
  },
  {
    boldText: 'Maximum accuracy',
  },
  {
    boldText: 'Precise standard curves',
  },
  {
    boldText: 'Less repetitive pipetting',
  },
]
