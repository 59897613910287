// @flow

import * as React from 'react'

import * as styles from './SerialDilution.module.css'

import {Note} from '../../ui-components'

export const NOTE_DATA = [
  {
    name: 'Labware',
    note: (
      <p>
        The OT-2 supports various labware for serial dilution. While the labware
        you use may vary based on the reagents, aluminum block, or temperature
        module requirements for your lab, we recommend the 12-channel reservoirs
        designed for automation to every lab doing serial dilutions. This
        12-channel reservoir, plus a 96-well PCR plate, clear flat bottom
        microplate, and molecular biology grade water, are what we used to
        optimize and verify our{' '}
        <a
          className={styles.links}
          href="https://protocols.opentrons.com/protocol/customizable_serial_dilution_ot2"
          target="_blank"
          rel="noopener noreferrer"
        >
          serial dilution protocol
        </a>
        . (
        <a
          className={styles.links}
          href="https://s3.amazonaws.com/opentrons-protocol-library-website/Technical+Notes/Serial+Dilution+OT2+Technical+Note.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          view technical note
        </a>
        ) If you prefer to use different labware, you can most likely find a
        close match in our Labware Library, or reach out to our support team for
        help setting it up.
      </p>
    ),
  },
  {
    name: 'Pipettes',
    note: (
      <p>
        Ensure that your OT-2 is fitted with suitable pipettes for the volumes
        you will need to work with. All of our pipettes can support serial
        dilution, but pipette channel and size can vary based on your protocol.
        For example, if you’re diluting a 200 ml sample, a p300 pipette would
        suit that protocol well. You can also use a multichannel pipette to
        perform the dilution faster without needing to adapt the protocol. You
        can even choose to reuse tips by having the pipette hover over the well
        to dispense liquid rather than trash them, saving resources and lab
        costs. Please review our Opentrons Pipette White Paper for more
        information on our pipettes and pipetting volumes as low as 1 µL.{' '}
      </p>
    ),
  },
]

export default function Notes() {
  return (
    <div>
      <section className={styles.notes}>
        <h4 className={styles.h4}>Notes</h4>
        {NOTE_DATA.map((note, i) => {
          return <Note key={i} {...note} />
        })}
      </section>
    </div>
  )
}
