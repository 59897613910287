// extracted by mini-css-extract-plugin
export var p = "SerialDilution-module--p--DOYgc";
export var h3 = "SerialDilution-module--h3--jrSo6";
export var h4 = "SerialDilution-module--h4--pn-p-";
export var h5 = "SerialDilution-module--h5--gvL8j";
export var hero = "SerialDilution-module--hero--YSVeQ";
export var hero_container = "SerialDilution-module--hero_container--9QEs-";
export var validation = "SerialDilution-module--validation--I4weP";
export var hero_h2 = "SerialDilution-module--hero_h2--VgWoh";
export var container = "SerialDilution-module--container--u-U2+";
export var desktop_menu = "SerialDilution-module--desktop_menu--moHbr";
export var fixed_scroll = "SerialDilution-module--fixed_scroll--eq0w9";
export var menu = "SerialDilution-module--menu--TIohs";
export var list = "SerialDilution-module--list--9LRxA";
export var callback = "SerialDilution-module--callback--ppybh";
export var button = "SerialDilution-module--button--quu8E";
export var pcr_content = "SerialDilution-module--pcr_content--WP0mL";
export var video = "SerialDilution-module--video--X6T3w";
export var video_details = "SerialDilution-module--video_details--DqbNC";
export var video_container = "SerialDilution-module--video_container--AEl1N";
export var figure = "SerialDilution-module--figure--UlZdc";
export var play_icon = "SerialDilution-module--play_icon--TdB6B";
export var overview_container = "SerialDilution-module--overview_container--ChhTe";
export var bold = "SerialDilution-module--bold--8SQhd";
export var workflow = "SerialDilution-module--workflow--3rECD";
export var bullet_blue = "SerialDilution-module--bullet_blue--slDMo";
export var benefits = "SerialDilution-module--benefits--chO+h";
export var links = "SerialDilution-module--links--iUvJW";
export var nac_workflow = "SerialDilution-module--nac_workflow--soF9E";
export var workflow_header = "SerialDilution-module--workflow_header--NwFLc";
export var material_container = "SerialDilution-module--material_container--Qi+jj";
export var additional_materials = "SerialDilution-module--additional_materials--XJ+YI";
export var opentrons_materials = "SerialDilution-module--opentrons_materials--uYSUn";
export var notes = "SerialDilution-module--notes--0mS+v";
export var resources_note = "SerialDilution-module--resources_note--qfRDc";
export var resources_content = "SerialDilution-module--resources_content---MMsZ";
export var related = "SerialDilution-module--related--+LSF0";
export var related_content_h4 = "SerialDilution-module--related_content_h4--lHDJr";
export var related_application = "SerialDilution-module--related_application--7PrCD";
export var related_button_container = "SerialDilution-module--related_button_container--DO4lc";
export var related_bg_color = "SerialDilution-module--related_bg_color--bSdDg";
export var related_container = "SerialDilution-module--related_container--JPprk";
export var related_button = "SerialDilution-module--related_button--QJIYi";
export var related_content_card = "SerialDilution-module--related_content_card--EBviN";