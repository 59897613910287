// @flow

import * as React from 'react'

import * as styles from './SerialDilution.module.css'

import {BenefitList} from '../../ui-components'

import {BENEFIT_LIST_DATA} from './serial-dilution-data'

export default function Benefits() {
  return (
    <div>
      <section className={styles.benefits}>
        <h4 className={styles.h4}>Benefits</h4>
        <p className={styles.p}>
          Automating serial dilution offers many benefits to traditional manual
          protocols, such as:
        </p>
        {BENEFIT_LIST_DATA.map((list, i) => {
          return <BenefitList key={i} {...list} />
        })}
      </section>
    </div>
  )
}
