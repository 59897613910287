import React from 'react'
import Page from '../components/Page'
import SEO from '../components/seo'
import SerialDilution from '../components/SerialDilution'

const SerialDilutionPage = () => (
  <Page>
    <SEO
      title="Automated Serial Dilutions Made Easy | Starts at $5,000"
      description="Just input your desired dilution factor, number of dilution, and volume on our Protocol Library, then download your protocol, and run it on your OT-2."
    />
    <SerialDilution />
  </Page>
)

export default SerialDilutionPage
