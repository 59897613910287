// @flow

import * as React from 'react'

import * as styles from './SerialDilution.module.css'

import {WorkFlow} from '../../ui-components'

import {WORKFLOW_DATA} from './serial-dilution-data'

export default function Workflow() {
  return (
    <div>
      <section className={styles.nac_workflow}>
        <h4 className={styles.h4}>Workflow</h4>
        <h5 className={styles.workflow_header}>Instructions</h5>
        {WORKFLOW_DATA.map((workflow, i) => {
          return <WorkFlow key={i} {...workflow} />
        })}
      </section>
    </div>
  )
}
