// @flow

import * as React from 'react'

import * as styles from './SerialDilution.module.css'

import {ResourceNote} from '../../ui-components'

import {RESOURCE_NOTE_DATA} from './serial-dilution-data'

export default function Resources() {
  return (
    <div>
      <section>
        <h4 className={styles.h4}>Resources</h4>
        <div className={styles.resources_content}>
          {RESOURCE_NOTE_DATA.map((resource, i) => {
            return (
              <div className={styles.resources_note} key={i}>
                <ResourceNote {...resource} />
              </div>
            )
          })}
        </div>
      </section>
    </div>
  )
}
