// @flow

import * as React from 'react'

import * as styles from './SerialDilution.module.css'
export default function Hero() {
  return (
    <>
      <section className={styles.hero}>
        <div className={styles.hero_container}>
          <div>
            <div className={styles.validation}>
              <span>Applications</span>
            </div>
            <h2 className={styles.hero_h2}>Automated Serial Dilution</h2>
          </div>
        </div>
      </section>
    </>
  )
}
