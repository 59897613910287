// @flow

import * as React from 'react'

import * as styles from './SerialDilution.module.css'

import VIDEO_STILL from './assets/SD-video-still.png'
import VIDEO_ICON from './assets/video-icon.svg'

type VideoProps = {
  onClick: (event: React.SyntheticMouseEvent<>) => mixed,
}

export default function Video(props: VideoProps) {
  const {onClick} = props
  return (
    <div className={styles.video}>
      <div className={styles.video_container}>
        <article>
          <figure className={styles.figure} onClick={onClick}>
            <img src={VIDEO_STILL} alt="" />
            <img src={VIDEO_ICON} alt="" className={styles.play_icon} />
            <div className={styles.video_details}>
              <span>Automating Serial Dilutions on the OT-2 (1:06)</span>
            </div>
          </figure>
        </article>
      </div>
    </div>
  )
}
